import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"

import { default as styles } from './SearchResult.module.css'

function getPostUriFromAlgoliaHit(hit) {
    const publishedDate = new Date(hit.published_at)
    const year = publishedDate.getFullYear()

    let month = publishedDate.getMonth()
    month += 1

    const isEnglishPost = hit.tags.filter(tag => tag === `#en`).length > 0
    const lang = `${isEnglishPost ? `en` : `fr` }`

    return `/${lang}/${year}/${String(month).padStart(2, 0)}/${hit.slug}/`
}

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className={styles.HitCount}>
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PageHit = ({ hit }) => (
  <div>
    <Link to={getPostUriFromAlgoliaHit(hit)}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className={styles.Hits} hitComponent={PageHit} />
  </Index>
)

export const SearchResult = ({ indices, className, show, isModale }) => {
    const classNames = [
        className,
        styles.container,
        show === true ? styles.visible : null,
        isModale === true ? styles.modale : null,
    ]

    return (
        <div className={classNames.join(' ')}>
            {indices.map(index => (
                <HitsInIndex index={index} key={index.name} />
            ))}
            <PoweredBy className={styles.PoweredBy} />
        </div>
    )
}
