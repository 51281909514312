import React, { useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { default as styles } from './SearchBox.module.css'

export default connectSearchBox(({ refine, className, onFocus }) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        const timoutID = setTimeout(() => {
            if (value.length !== 0 && value.length < 3) {
                return
            }
            refine(value)
        }, 200)

        return () => clearTimeout(timoutID)
    }, [value])

    return (
        <form>
            <input
                className={`${className} ${styles.input}`}
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={value}
                onChange={e => setValue(e.target.value)}
                onFocus={onFocus}
            />
        </form>
    )
})
