import React, { useContext } from 'react'

import LocalizationContext, { LOCALES } from '../../../LocalizationContext'

import { default as styles } from './NewsletterWidget.module.css'

export const NewsletterWidget = () => {
    const { locale } = useContext(LocalizationContext)
    const formAction = `//travel-and-food.us3.list-manage.com/subscribe/post?u=4a6bcc4bd248735137566339b&id=edde558305`

    return (
        <form className={styles.container} action={formAction} method="post" target="_blank" noValidate>
            {locale === LOCALES.FR ? (
                <>
                    <p className={styles.text}>
                        Ne manquez rien de ce qui se passe sur le blog en vous inscrivant à la newsletter ci-dessous
                    </p>
                    <input className={styles.input} type="email" name="EMAIL" placeholder="Votre adresse e-mail" aria-label="Votre adresse e-mail" />
                    <button type="submit" className={styles.button}>
                        S&apos;inscrire
                    </button>
                </>
            ) : null}

            {locale === LOCALES.EN ? (
                <>
                    <p className={styles.text}>
                        Don&apos;t miss a single recipe of the blog by subscribing to our newsletter below.
                    </p>
                    <input className={styles.input} type="email" name="EMAIL" placeholder="Your email address" aria-label="Your email address" />
                    <button type="submit" className={styles.button}>
                        Subscribe
                    </button>
                </>
            ) : null}
        </form>
    )
}
