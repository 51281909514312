import React from 'react'
import { string } from 'prop-types'

import { default as styles } from './ColumnSeparator.module.css'

export const ColumnSeparator = ({ text }) => (
    <div className={styles.separator}>
        {text ? (
            <span className={styles.text}>{text}</span>
        ) : null}
    </div>
)

ColumnSeparator.propTypes = {
    text: string,
}
