import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../LocalizationContext'

import { default as styles } from './BookChickenPreview.module.css'

export const BookChickenPreview = () => {
    const { locale } = useContext(LocalizationContext)
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "100-pour-100-poulet/00-couverture.jpg" }) {
                childImageSharp {
                    fixed(width: 180) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.container}>
            <Link className={styles.link} to={locale === LOCALES.EN ? `https://shop.travel-and-food.com/products/livre-100-poulet` : `https://shop.travel-and-food.com/products/livre-100-poulet`}>
                <img
                    className={styles.img}
                    src={data.file.childImageSharp.fixed.src}
                    srcSet={data.file.childImageSharp.fixed.srcSet}
                    width={data.file.childImageSharp.fixed.width}
                    height={data.file.childImageSharp.fixed.height}
                    alt={locale === LOCALES.EN ? `Livre 100% poulet` : `100% chicken The book`}
                />
                {locale === LOCALES.EN ? (
                    <>
                        <p className={styles.desc}>
                            Chicken from every angle! A world tour of 50 recipes to rediscover chicken.
                        </p>
                        <span className={styles.button}>
                            Pre-order your copy !
                        </span>
                    </>
                ) : null}
                {locale === LOCALES.FR ? (
                    <>
                        <p className={styles.desc}>
                            Le poulet sous toutes ses coutures ! Un tour du monde de 50 recettes pour redécouvrir le poulet.
                        </p>
                        <span className={styles.button}>
                            Précommandez le votre !
                        </span>
                    </>
                ) : null}
            </Link>
        </div>
    )
}
