import React, { useContext } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../../LocalizationContext'

import { default as styles } from './LocaleFlagButton.module.css'

export const LocaleFlagButton = () => {
    const { locale, localizedURL: localizedVersion } = useContext(LocalizationContext)
    const defaultPath = locale === LOCALES.EN ? `/${LOCALES.FR}/` : `/${LOCALES.EN}/`
    const data = useStaticQuery(graphql`
        {
            englishFlag: file(relativePath: { eq: "en-flag.png" }) {
                childImageSharp {
                fixed(width: 30) {
                    ...GatsbyImageSharpFixed
                }
                }
            }
            frenchFlag: file(relativePath: { eq: "fr-flag.png" }) {
                childImageSharp {
                fixed(width: 30) {
                    ...GatsbyImageSharpFixed
                }
                }
            }
        }
    `)

    return (
        <Link to={localizedVersion || defaultPath}>
            {locale === LOCALES.EN ? (
                <img
                    className={styles.icon}
                    src={data.frenchFlag.childImageSharp.fixed.src}
                    srcSet={data.frenchFlag.childImageSharp.fixed.srcSet}
                    width={data.frenchFlag.childImageSharp.fixed.width}
                    height={data.frenchFlag.childImageSharp.fixed.height}
                    alt="Version française"
                />
            ) : null}
            {locale === LOCALES.FR ? (
                <img
                    className={styles.icon}
                    src={data.englishFlag.childImageSharp.fixed.src}
                    srcSet={data.englishFlag.childImageSharp.fixed.srcSet}
                    width={data.englishFlag.childImageSharp.fixed.width}
                    height={data.englishFlag.childImageSharp.fixed.height}
                    alt="English version"
                />
            ) : null}
        </Link>
    )
}
