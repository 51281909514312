import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../LocalizationContext'

import { default as styles } from './AboutWidget.module.css'

export const AboutWidget = () => {
    const { locale } = useContext(LocalizationContext)
    const { ghostAuthor } = useStaticQuery(graphql`
        {
            ghostAuthor(slug: {eq: "maeva"}) {
                profile_image
                name
            }
        }
    `)

    return (
        <div>
            <header className={styles.header}>
                <img
                    className={styles.photo}
                    src={ghostAuthor.profile_image}
                    alt=""
                />
                <strong className={styles.title}>{ghostAuthor.name}</strong>
            </header>
            <p className={styles.bio}>
                {locale === LOCALES.FR ? (
                    <>
                        Je suis styliste et photographe culinaire depuis 2014,
                        j’adore partager mes recettes et mes découvertes culinaires
                        avec vous ici. J’y partage également nos aventures en famille
                        à travers le monde en réalisant des city guides et des
                        articles plus spécifiques.
                    </>
                ) : null}
                {locale === LOCALES.EN ? (
                    <>
                        I&apos;ve been food blogging and food styling since 2014, I love sharing
                        my recipes and discoveries with you on this platform. I also share our
                        family adventures around the globe by making city guides and other
                        more specific posts.
                    </>
                ) : null}
            </p>
        </div>
    )
}
