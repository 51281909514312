import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import LocalizationContext from "../../../LocalizationContext"
import LocaleFlagButton from "./LocaleFlagButton"
import MenuButton from "./MenuButton"
import SearchButton from "./SearchButton"

import { default as styles } from "./StickyHeader.module.css"

export class StickyHeader extends Component {
    static propTypes = {
        siteTitle: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                path: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
    };

    static defaultProps = {
        siteTitle: ``,
        items: [],
    };

    state = {
        isStuck: false,
    };

    componentDidMount() {
        window.addEventListener(`scroll`, this.handleScroll.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this.handleScroll.bind(this))
    }

    handleScroll() {
        window.requestAnimationFrame(() => {
            const startElement = this.stikyAnchor

            if (startElement == null) {
                return
            }

            const offsetValues = this.getOffset(startElement)
            const scrollTop = window.pageYOffset

            if (scrollTop > offsetValues.top) {
                if (!this.state.isStuck) {
                    this.setState({ isStuck: true })
                }

                return
            }

            this.setState({ isStuck: false })
        })
    }

    getOffset(htmlElement) {
        const bounding = htmlElement.getBoundingClientRect()

        return {
            top: bounding.top + document.body.scrollTop,
            left: bounding.left + document.body.scrollLeft,
        }
    }

    render() {
        const { items, siteTitle } = this.props
        const { isStuck } = this.state

        return (
            <header className={styles.header}>
                <div
                    className={`${styles.navbar} ${
                        isStuck ? styles.navbarFixed : ``
                    }`}
                >
                    <div className={styles.sideActions}>
                        <SearchButton className={styles.searchButton} />
                        <MenuButton
                            items={items}
                            className={styles.moreButton}
                        />
                    </div>
                    <nav className={styles.nav}>
                        <div className={styles.content}>
                            <ul className={styles.menuList}>
                                {items.map((item, index) => (
                                    <li key={index}>
                                        <Link
                                            className={styles.menuItem}
                                            to={item.path}
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </nav>
                    <div className={styles.sideActions}>
                        <LocaleFlagButton />
                    </div>
                </div>
                <div
                    className={styles.content}
                    onScroll={() => console.log(`lkqsjdk`)}
                >
                    <h1 className={styles.title}>
                        <LocalizationContext.Consumer>
                            {({ locale }) => (
                                <Link
                                    to={`/${locale}/`}
                                    className={styles.titleLink}
                                >
                                    {siteTitle}
                                </Link>
                            )}
                        </LocalizationContext.Consumer>
                    </h1>
                </div>
                <div ref={c => (this.stikyAnchor = c)} />
            </header>
        )
    }
}
