import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../LocalizationContext'

import StickyHeader from './StickyHeader'
import ColumnSeparator from './ColumnSeparator'
import SocialWidget from './SocialWidget'
import DoucesSoupesPreview from './DoucesSoupesPreview'
import BookChickenPreview from './BookChickenPreview'
import AboutWidget from './AboutWidget'
import NewsletterWidget from './NewsletterWidget'
import GhostMenu from './GhostMenu'
import SearchWidget from './SearchWidget'
import AdWidget from './AdWidget'

// Styles
import '../../../styles/_base.css'

import { default as styles } from './Layout.module.css'
import { LayoutContext } from './LayoutContext'
import NewsletterPopin from './NewsletterPopin'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
export const DefaultLayout = ({ data, children, bodyClass, pathname, leftSidebar = null, rightSidebar = null }) => {
    const [displaySidebar, setSidebarVisibility] = useState(false)
    const { locale } = useContext(LocalizationContext)

    useEffect(() => {
        const query = window.matchMedia(`(max-width: 670px)`)
        const applyQuery = (x) => {
            if (x.matches) {
                setSidebarVisibility(false)

                return
            }

            setSidebarVisibility(true)
        }

        applyQuery(query)
        query.addListener(applyQuery)
    })

    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    const menuItems = getMenuItems(locale)

    const createSidebarFromWidgets = (sidebar) => {
        if (!displaySidebar) {
            return null
        }

        return (
            <aside className={styles.sidebar}>
                {sidebar}
            </aside>
        )
    }

    const renderLeftSidebar = () => {
        if (leftSidebar != null) {
            return createSidebarFromWidgets(leftSidebar)
        }

        return createSidebarFromWidgets(
            <>
                <AboutWidget />
                <ColumnSeparator />
                <SocialWidget twitterUrl={twitterUrl} facebookUrl={facebookUrl} />
                <ColumnSeparator />
            </>
        )
    }

    const renderRightSidebar = () => {
        if (rightSidebar != null) {
            return createSidebarFromWidgets(rightSidebar)
        }

        return createSidebarFromWidgets(
            <>
                <SearchWidget />
                <ColumnSeparator text="Books" />
                <BookChickenPreview />
                <DoucesSoupesPreview />
                <ColumnSeparator text="Newsletter" />
                <NewsletterWidget />
                <ColumnSeparator text="Ads" />
                <AdWidget pathname={pathname}/>
            </>
        )
    }

    return (
    <>
        <Helmet>
            <html lang={locale || site.lang} />
            <meta name="facebook-domain-verification" content="n98cmgwz4rofmhl2dyoi7974508nra" />
            <meta name="am-api-token" content="aNCq39aFC" />
            <style type="text/css">{`${site.codeinjection_styles}`}</style>
            <body className={bodyClass} />
        </Helmet>

        <LayoutContext.Provider value={{ isSmallScreen: !displaySidebar }}>
            <div className={styles.viewport}>
                <div className="viewport-top">
                    <StickyHeader
                        siteTitle={site.title}
                        items={menuItems}
                    />

                    <main className="site-main">
                        <div className={styles.container}>
                            {renderLeftSidebar()}
                            <div className={styles.content}>
                                {children}
                            </div>
                            {renderRightSidebar()}
                        </div>
                    </main>
                </div>

                <div className="viewport-bottom">
                    <hr style={{ width: `75%`, margin: `auto` }} />
                    <footer className={styles.siteFoot}>
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-right">
                                <GhostMenu data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                            <div className="site-foot-nav-left">
                                © 2015-{new Date().getFullYear()} {site.title}. {locale === LOCALES.EN ? (
                                    <>Designed and developed by <a href="https://nathanaelcherrier.com/en/" target="_blank" rel="noopener noreferrer">Nathanaël Cherrier</a>.</>
                                ) : (
                                    <>Conçu et développé par <a href="https://nathanaelcherrier.com/fr/" target="_blank" rel="noopener noreferrer">Nathanaël Cherrier</a>.</>
                                )}
                            </div>
                        </div>
                    </footer>
                </div>
                {/* <NewsletterPopin /> */}
            </div>
        </LayoutContext.Provider>
        <script async src="https://snippet.affilimate.io"/>
    </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    leftSidebar: PropTypes.node,
    rightSidebar: PropTypes.node,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    pathname: PropTypes.string.isRequired,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

function getMenuItems(locale = LOCALES.EN) {
    if (locale === LOCALES.EN) {
        return [
            { name: `Recipes`, path: `/en/recipes/` },
            { name: `Travels`, path: `/en/travel/` },
            { name: `Family`, path: `/en/family/` },
            { name: `Shop`, path: `https://shop.travel-and-food.com/` },
        ]
    }

    return [
        { name: `Recettes`, path: `/fr/recettes/` },
        { name: `Voyages`, path: `/fr/voyages/` },
        { name: `Famille`, path: `/fr/famille/` },
        { name: `Boutique`, path: `https://shop.travel-and-food.com/` },
    ]
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export { DefaultLayoutSettingsQuery as Layout }
