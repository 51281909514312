import React, { createRef, useState } from 'react'
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"

import SearchBox from './SearchBox'
import SearchResult from './SearchResult'
import useClickOutside from './useClickOutside'

import { default as styles } from './SearchWidget.module.css'

const indices = [{ name: `Posts`, title: `Posts` }]

const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const searchClient = {
    search(requests) {
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                      hits: [],
                      nbHits: 0,
                      nbPages: 0,
                      page: 0,
                      processingTimeMS: 0,
                })),
            })
        }

        return algoliaClient.search(requests)
    },
}

export function SearchWidget(props) {
    const { onClose = () => {}, isModale = false } = props
    const rootRef = createRef()
    const [query, setQuery] = useState()
    const [hasFocus, setFocus] = useState(false)

    const classNames = [
        styles.container,
        isModale === true && styles.modale,
    ]

    useClickOutside(rootRef, () => setFocus(false))

    return (
        <div className={classNames.join(' ')} ref={rootRef}>
            <div className={styles.modaleCloseButton} onClick={onClose}>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="times"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 352 512"
                >
                    <path
                        fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                    >
                    </path>
                </svg>
            </div>
            <InstantSearch
                searchClient={searchClient}
                indexName={indices[0].name}
                onSearchStateChange={({ query }) => setQuery(query)}
            >
                <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
                <SearchResult
                    isModale={isModale}
                    show={query && query.length > 0 && hasFocus}
                    indices={indices}
                />
            </InstantSearch>
        </div>
    )
}
