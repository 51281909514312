import React from 'react'
import PropTypes from 'prop-types'

import FacebookButton from './FacebookButton'
import TwitterButton from './TwitterButton'
import InstagramButton from './InstagramButton'
import PinterestButton from './PinsterestButton'
import YoutubeButton from './YoutubeButton'

export const SocialWidget = ({
    twitterUrl = `https://www.twitter.com/TravelnFoodBlog`,
    facebookUrl = `https://www.facebook.com/blogtravelandfood/`,
    instagramUrl = `https://www.instagram.com/maevatravelandfood/`,
    youtubeUrl = `https://www.youtube.com/channel/UCTFzmnR_8bJLiq-pIM7YQog`,
    pinterestUrl = `https://www.pinterest.fr/travelnfoodblog/`,
}) => (
    <div>
        <FacebookButton link={facebookUrl} />
        <TwitterButton link={twitterUrl} />
        <InstagramButton link={instagramUrl} />
        <PinterestButton link={pinterestUrl} />
        <YoutubeButton link={youtubeUrl} />
    </div>
)

SocialWidget.propTypes = {
    twitterUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    instagramUrl: PropTypes.string,
    pinterestUrl: PropTypes.string,
}
