import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import LocalizationContext, { LOCALES } from '../../../LocalizationContext'

import { default as styles } from './DoucesSoupesPreview.module.css'

export const DoucesSoupesPreview = () => {
    const { locale } = useContext(LocalizationContext)
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "douces-soupes/00-couverture.png" }) {
                childImageSharp {
                    fixed(width: 180) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.container}>
            <Link className={styles.link} to={locale === LOCALES.EN ? `https://shop.travel-and-food.com/products/douces-soupes?variant=42168786190551` : `https://shop.travel-and-food.com/products/douces-soupes?variant=42168786223319`}>
                <img
                    className={styles.img}
                    src={data.file.childImageSharp.fixed.src}
                    srcSet={data.file.childImageSharp.fixed.srcSet}
                    width={data.file.childImageSharp.fixed.width}
                    height={data.file.childImageSharp.fixed.height}
                    alt={locale === LOCALES.EN ? `Livre Douces Soupes` : `Douces Soupes The book`}
                />
                {locale === LOCALES.EN ? (
                    <>
                        <p className={styles.desc}>
                            25 delicious soup recipes to get more vegetables in this Winter and have yummy meals ready in no-time.
                        </p>
                        <span className={styles.button}>
                            Order your copy !
                        </span>
                    </>
                ) : null}
                {locale === LOCALES.FR ? (
                    <>
                        <p className={styles.desc}>
                            25 délicieuses recettes de soupes diverses et variées pour manger plus de légumes cet hiver et se régaler !
                        </p>
                        <span className={styles.button}>
                            Commandez le votre !
                        </span>
                    </>
                ) : null}
            </Link>
        </div>
    )
}
