import { createContext } from 'react'

export const LOCALES = Object.freeze({
    EN: `en`,
    FR: `fr`,
})

const LocalizationContext = createContext({
    locale: LOCALES.FR,
    localizedURL: `/${LOCALES.EN}/`,
})

export default LocalizationContext
