import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LocaleFlagButton from '../LocaleFlagButton'

import { default as styles } from './MenuButton.module.css'

export const MenuButton = (props) => {
    const [opened, open] = useState(false)
    const { items, className } = props

    return (
        <>
            <button onClick={() => open(!opened)} className={`${styles.button} ${className}`}>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ellipsis-v"
                    className={styles.svg}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 512"
                >
                    <path
                        className={styles.svgPath}
                        fill="orange"
                        d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
                    >
                    </path>
                </svg>
            </button>
            {opened === true ? (
                <div className={styles.modalContainer}>
                    <div className={styles.modaleCloseButton} onClick={() => open(!opened)}>
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="times"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 352 512"
                        >
                            <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            >
                            </path>
                        </svg>
                    </div>
                    <p className={styles.modaleTitle}>Travel & Food</p>
                    {items.map((item, index) => (
                        <Link className={styles.modaleItem} key={index} to={item.path}>
                            {item.name}
                        </Link>
                    ))}
                    <div className={styles.modaleLang} >
                        <LocaleFlagButton />
                    </div>
                </div>
            ) : null}
        </>
    )
}

MenuButton.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
        })
    ),
    className: PropTypes.string,
}
